import React from "react";
import { useSpring, animated } from "react-spring";

const NumberAnimation = ({
  n,
  text,
  duration = 500,
  fixed = 0
}: {
  n: number;
  text?: string;
  duration?: number;
  fixed?: number;
}) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 500,
    config: { duration: duration },
  });
  return (
    <div className="flex flex-row items-center justify-center">
      <animated.div>{number.to((n) => n.toFixed(fixed))}</animated.div>
     {text && <span>{text}</span>}
    </div>
  );
};

export default NumberAnimation;
