import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="bg-grayLight w-full mx-0 sm:py-16 py-16 sm:px-4">
      <div className="py-4 lg:py-8 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">
          Privacy Policy
        </h2>
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          UsefullTool built the Converter app as a Commercial app. This SERVICE
          is provided by UsefullTool and is intended for use as is.
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          This page is used to inform visitors regarding my policies with the
          collection, use, and disclosure of Personal Information if anyone
          decided to use my Service.
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          If you choose to use my Service, then you agree to the collection and
          use of information in relation to this policy. The Personal
          Information that I collect is used for providing and improving the
          Service. I will not use or share your information with anyone except
          as described in this Privacy Policy.
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at the converter unless
          otherwise defined in this Privacy Policy.
        </p>{" "}
        <p className="mb-2 lg:mb-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">
            Information Collection and Use
          </h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          For a better experience, while using our Service, I may require you to
          provide us with certain personally identifiable information. The
          information that I request will be retained on your device and is not
          collected by me in any way.
        </p>{" "}
        <div>
          <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
            The app does use third party services that may collect information
            used to identify you.
          </p>{" "}
          <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
            Link to privacy policy of third party service providers used by the
            app{" "}
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 underline hover:no-underline"
            >
              Google Play Services
            </a>
          </p>{" "}
          <ul>
            <li></li>
          </ul>
        </div>{" "}
        <p className="mb-2 lg:mb-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">Log Data</h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          I want to inform you that whenever you use my Service, in a case of an
          error in the app I collect data and information (through third party
          products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address,
          device name, operating system version, the configuration of the app
          when utilizing my Service, the time and date of your use of the
          Service, and other statistics.
        </p>{" "}
        <p className="mb-2 lg:mb-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">Cookies</h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          This Service does not use these “cookies” explicitly. However, the app
          may use third party code and libraries that use “cookies” to collect
          information and improve their services. You have the option to either
          accept or refuse these cookies and know when a cookie is being sent to
          your device. If you choose to refuse our cookies, you may not be able
          to use some portions of this Service.
        </p>{" "}
        <p className="mb-2 lg:mb-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">Service Providers</h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          I may employ third-party companies and individuals due to the
          following reasons:
        </p>{" "}
        <ul className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          <li>To facilitate our Service;</li>{" "}
          <li>To provide the Service on our behalf;</li>{" "}
          <li>To perform Service-related services; or</li>{" "}
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          I want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>{" "}
        <p className="mb-2 lg:mb-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">Security</h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          I value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and I cannot
          guarantee its absolute security.
        </p>{" "}
        <p className="mb-2 lg:mb-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">Links to Other Sites</h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 font-light text-gray-500 sm:text-xl indent-1">
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by me. Therefore, I strongly advise
          you to review the Privacy Policy of these websites. I have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>{" "}
        <p className="mb-2 lg:mb-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">Children’s Privacy</h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 mt-4 font-light text-gray-500 sm:text-xl indent-1">
          These Services do not address anyone under the age of 13. I do not
          knowingly collect personally identifiable information from children
          under 13 years of age. In the case I discover that a child under 13
          has provided me with personal information, I immediately delete this
          from our servers. If you are a parent or guardian and you are aware
          that your child has provided us with personal information, please
          contact me so that I will be able to do necessary actions.
        </p>{" "}
        <p className="mb-2 lg:mb-4 mt-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">
            Changes to This Privacy Policy
          </h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 mt-4 font-light text-gray-500 sm:text-xl indent-1">
          I may update our Privacy Policy from time to time. Thus, you are
          advised to review this page periodically for any changes. I will
          notify you of any changes by posting the new Privacy Policy on this
          page.
        </p>{" "}
        <p className="mb-2 lg:mb-4 mt-4 font-light text-gray-500 sm:text-xl indent-1">
          This policy is effective as of 2021-03-24
        </p>{" "}
        <p className="mb-2 lg:mb-4 mt-4 text-gray-600 sm:text-xl">
          <h3 className="text-xl font-semibold">Contact Us</h3>
        </p>{" "}
        <p className="mb-2 lg:mb-4 mt-4 font-light text-gray-500 sm:text-xl indent-1">
          If you have any questions or suggestions about my Privacy Policy, do
          not hesitate to contact me at usefulltoolcom@gmail.com.
        </p>{" "}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
