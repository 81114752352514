const Navigation: React.FC<{
  onContactClick: () => void;
  onAboutClick: () => void;
}> = ({ onContactClick, onAboutClick }) => {
  return (
    <nav className="hidden sm:block">
      <ul className="flex space-x-4">
        <li className="hover:underline">
          <a href="/" className="font-medium">
            HOME
          </a>
        </li>
        <li className="hover:underline">
          <a
            href="/"
            className="font-medium"
            onClick={(e) => {
              e.preventDefault();
              onAboutClick();
            }}
          >
            ABOUT
          </a>
        </li>
        <li className="hover:underline">
          <a
            href="/"
            className="font-medium"
            onClick={(e) => {
              e.preventDefault();
              onContactClick();
            }}
          >
            CONTACT
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default Navigation;
