import React from "react";
import Hero from "../../components/Home/Hero/Hero";
import HomeInfo from "../../components/Home/Info/HomeInfo";
import Contact from "../../components/Home/Contact/Contact";
import About from "../../components/Home/About/About";

interface HomeProps {
  contactRef: React.RefObject<any>;
  aboutRef: React.RefObject<any>;
}

const Home: React.FC<HomeProps> = ({ contactRef,aboutRef }) => {
  return (
    <>
      <Hero />
      <HomeInfo />
      <About aboutRef={aboutRef} />
      <Contact contactRef={contactRef} />
    </>
  );
};

export default Home;
