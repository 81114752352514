import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="w-full flex flex-col items-center bg-footerColor  font-medium text-sm text-gray-500 border-t-[1px] pt-[20px] pb-[20px]  z-20">
      <div className="w-full desktop:w-[1440px] flex flex-col gap-1 md:gap-2 md:flex-row items-center laptop:w-full m-auto pr-[10px] pl-[10px]">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          className="hover:text-gray-800"
        >
          Home
        </Link>
        <Link
          to="/apps/converters/privacypolicy"
          className="hover:text-gray-800"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          Privacy Policy
        </Link>
        <Link
          to="/apps/converters/termsconditions"
          className="hover:text-gray-800"
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
};

export default Footer;
