import React from "react";
import HeroText from "./HeroText";
import HeroImage from "./HeroImage";

const Hero: React.FC = () => {
  return (
    <div className="w-full relative custombg pr-[10px] pl-[10px] flex h-[600px] sm:h-[500px] lg:h-[700px] flex-col items-center overflow-hidden">
      <div className="flex sm:flex-row flex-col items-center sm:items-start mt-[70px] w-full 2xl:w-[1440px] gap-4">
        <div className="flex w-full mt-0 md:mt-[30px] flex-col md:flex-row">
          <div className="w-full  lg:w-1/2 2xl:w-1/2 flex flex-col justify-start">
            <HeroText />
          </div>
          <div className="w-full lg:w-1/2 2xl:w-1/2 flex items-center justify-center mt-5 lg:mt-10">
            <HeroImage />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full">
        <svg
          className="waves z-0"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fafbfe" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Hero;
