import { IconSuccess } from "../../assets/icons/Icons";

const AlertBox = ({ text }: { text: string }) => {
  return (
    <div
      className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50"
      role="alert"
    >
      <IconSuccess />
      <span className="sr-only">Info</span>
      <div>{text}</div>
    </div>
  );
};

export default AlertBox;
