import React, { useEffect, useState } from "react";
import { HamburgerIcon } from "../../../assets/icons/Icons";
import Navigation from "./Nav/Navigation";
import { useLocation } from "react-router-dom";

interface HeaderProps {
  onContactClick: () => void;
  onAboutClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onContactClick, onAboutClick }) => {
  const [headerChange, setHeaderChange] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/apps/converters/privacypolicy" ||
      location.pathname === "/apps/converters/termsconditions"
    ) {
      setHeaderChange(400);
    } else {
      window.addEventListener("scroll", () => {
        if (window.scrollY <= 10) {
          setHeaderChange(0);
        } else if (window.scrollY > 10 && window.scrollY < 400) {
          setHeaderChange(399);
        } else if (window.scrollY >= 400) {
          setHeaderChange(400);
        }
      });
    }
  }, [headerChange, location]);
  return (
    <header
      className={`w-full fixed top-0 left-0 z-20 ${
        headerChange > 10 && headerChange < 400
          ? `bg-opacity-50 backdrop-filter backdrop-blur-lg shadow-md`
          : headerChange >= 400
          ? `custombg backdrop-blur-lg shadow-md`
          : `bg-transparent`
      }`}
    >
      <div className="h-[60px] w-full 2xl:w-[1440px] m-auto pr-[10px] pl-[10px] flex justify-center items-center sm:justify-between">
        <div className="block sm:hidden absolute left-0 top-0">
          <button className="hidden lg:hidden px-2 py-2 text-white">
            <HamburgerIcon />
          </button>
        </div>
        <div>
          <a
            href="/"
            className={`${
              headerChange ? `text-shadow` : ``
            } font-bold text-[1.4rem] text-white hover:underline`}
          >{`USEFULLTOOL`}</a>
        </div>
        <Navigation
          onContactClick={onContactClick}
          onAboutClick={onAboutClick}
        />
      </div>
    </header>
  );
};

export default Header;
