import React from "react";
import googlePlayBadge from "../../../assets/images/google-play-badge-logo.png";

const About = ({ aboutRef }: { aboutRef: React.RefObject<any> }) => {
  return (
    <div
      className="bg-grayLight w-full mx-0 sm:py-16 py-6 sm:px-4"
      ref={aboutRef}
    >
      <div className="py-4 lg:py-8 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">
          About Us
        </h2>
        <p className="mb-4 lg:mb-8 font-light text-center text-gray-500 sm:text-xl">
          Our mobile and web apps are designed to make your life easier. Our
          Unit Converter apps are the best, simple and lightweight solutions in
          the market. Download or use them online for free.
        </p>
        <p className="flex items-center justify-center gap-2">
          <a
            href="https://play.google.com/store/apps/dev?id=7458294275985853740"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block"
          >
           
              <img
                src={googlePlayBadge}
                className="block w-48 self-center"
                alt="UsefullTool Google Play Store"
              />

          </a>
        </p>
      </div>
    </div>
  );
};

export default About;
