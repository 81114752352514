import React from "react";
import NumberAnimation from "../../shared/NumberAnimation";

const HomeInfo = () => {
  return (
    <div className="container mx-auto my-16 sm:px-4">
      <div className="flex flex-wrap  justify-center">
        <div className="xl:w-1/4 pr-4 pl-4 lg:w-1/3 pr-4 pl-4 md:w-1/3 pr-4 pl-4 sm:w-1/2 pr-4 pl-4 mb-4 md:mb-6 lg:mb-0">
          <div className="single-fact p-6 text-center">
            <h3 className="title text-6xl font-semibold text-blue-950"><NumberAnimation n={10} text="K" /></h3>
            <p className="mb-0 mt-6 text-gray-500">
              People are already using our mobile and web apps daily.
            </p>
          </div>
        </div>
        <div className="xl:w-1/4 pr-4 pl-4 lg:w-1/3 pr-4 pl-4 md:w-1/3 pr-4 pl-4 sm:w-1/2 pr-4 pl-4 mb-4 md:mb-6 lg:mb-0">
          <div className="single-fact p-6 text-center">
            <h3 className="title text-6xl font-semibold text-blue-950"><NumberAnimation n={94} text="%" duration={2500} /></h3>
            <p className="mb-0 mt-6 text-gray-500">
              Satisfaction rate comes from our customers.
            </p>
          </div>
        </div>
        <div className="xl:w-1/4 pr-4 pl-4 lg:w-1/3 pr-4 pl-4 md:w-1/3 pr-4 pl-4 sm:w-1/2 pr-4 pl-4 mb-4 md:mb-6 lg:mb-0">
          <div className="single-fact p-6 text-center">
            <h3 className="title text-6xl font-semibold text-blue-950"><NumberAnimation n={4.9} fixed={1} duration={2000} /></h3>
            <p className="mb-0 mt-6 text-gray-500">
              Average customer ratings we have got all over products.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInfo;
