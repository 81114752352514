const HeroText: React.FC = () => {
  return (
    <>
      <div className="flex flex-1 flex-col mt-1 mx-20 md:mt-10 lg:mt-0 lg:justify-center h-full text-center sm:text-left">
        <h1 className="w-full drop-shadow-lg shadow-black text-white font-bold 2xl:text-[4rem] 2xl:leading-[4.5rem] xl:text-[3rem] xl:leading-[3.5rem] lg:text-[3.0rem] lg:leading-[3.5rem] md:text-[2.0rem] md:leading-[3rem] text-[2rem] leading-[2.5rem]">
          WE MAKE <span className="text-black">APPS</span>
        </h1>
        <h2 className="w-full drop-shadow-lg shadow-black text-white font-medium 2xl:text-[2.5rem] 2xl:leading-[3rem]  xl:text-[2.5rem] xl:leading-[3rem] lg:text-[2.0rem] lg:leading-[2.5rem] md:text-[1.5rem] md:leading-[2rem] text-[1.5rem] leading-[2rem]">
          for <span className="text-black">Mobile Devices</span> and <span className="text-black">Web</span>
        </h2>
        <h3 className="text-white pt-1 lg:pt-5 font-medium">
          Our mobile and web apps are designed to make your life easier. Our
          Unit Converter apps are the best, <span className="font-bold">simple</span> and <span className="text-black font-bold">lightweight</span> solutions in
          the market. Download or use them online for free.
        </h3>
      </div>
      <div className="flex flex-1 sm:pt-[40px] w-[400px] sm:w-[180px] lg:w-[200px] xl:w-[250px] items-center justify-center"></div>
    </>
  );
};

export default HeroText;
