import React, { useRef } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/apps/converters/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/apps/converters/TermsConditions/TermsConditions";

const App: React.FC = () => {
  const contactRef = useRef<HTMLElement | null>(null);
  const aboutRef = useRef<HTMLElement | null>(null);
  const contactClickHandler = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const aboutClickHandler = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Layout onContactClick={contactClickHandler} onAboutClick={aboutClickHandler} />}>
        <Route
          index
          element={<Home aboutRef={aboutRef} contactRef={contactRef} />}
        />
        <Route
          path="apps/converters/privacypolicy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="apps/converters/termsconditions"
          element={<TermsConditions />}
        />
      </Route>
    </Routes>
  );
};

export default App;
