import React, { useState } from "react";
import { SendIcon } from "../../../assets/icons/Icons";
import AlertBox from "../../shared/AlertBox";

const Contact = ({ contactRef }: { contactRef: any }) => {
  const [message, setMessage] = useState("");

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage("You message has been sent!");
    e.currentTarget.reset();
  };
  return (
    <div
      className="bg-white w-full mx-0 sm:py-16 py-0 sm:px-4"
      ref={contactRef}
    >
      <section>
        <div className="py-4 lg:py-8 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">
            Contact Us
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">
            Got a technical issue? Want to send feedback about a beta feature?
            Need details about our Business plan? Let us know.
          </p>
          {message && <AlertBox text={message} />}
          {message && (
            <div className="flex items-center justify-center">
              <button
                onClick={() => setMessage("")}
                className="flex text-center items-center gap-1 py-2 px-4 text-sm font-semibold text-center text-white rounded-lg bg-slate-500 sm:w-fit hover:bg-slate-400 focus:ring-4 focus:outline-none focus:ring-primary-300"
              >
                <span>RETURN</span>
              </button>
            </div>
          )}
          <form
            onSubmit={submitHandler}
            className={`space-y-8 ${message ? "hidden" : ""}`}
          >
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                placeholder="name@usefulltool.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Let us know how we can help you"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Your message
              </label>
              <textarea
                id="message"
                rows={6}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                placeholder="Leave a comment..."
              ></textarea>
            </div>
            <button
              type="submit"
              className="flex flex-row items-center gap-1 py-2 px-4 text-sm font-semibold text-center text-white rounded-lg bg-slate-500 sm:w-fit hover:bg-slate-400 focus:ring-4 focus:outline-none focus:ring-primary-300"
            >
              <SendIcon color="#fff" /> <span>SEND MESSAGE</span>
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Contact;
