import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";

interface LayoutProps {
  onContactClick: () => void;
  onAboutClick: () => void;
}

const Layout: React.FC<LayoutProps> = ({ onContactClick, onAboutClick }) => {
  return (
    <div className="flex flex-col m-auto w-full relative justify-center items-center">
      <Header onContactClick={onContactClick} onAboutClick={onAboutClick} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
